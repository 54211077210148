/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Ambassador from './icons/ambassador.svg'
import Annonce from './icons/annonce.svg'
import ArrowLeftIcon from './icons/arrow-left.svg'
import ArrowRightIcon from './icons/arrow-right.svg'
import AudioPlay from './icons/audio-play.svg'
import AudioPause from './icons/audio-pause.svg'
import AvatarSun from './icons/avatar-sun.svg'
import BreadcrumbSeparatorIcon from './icons/breadcrumb-separator.svg'
import Calendar from './icons/calendar.svg'
import CategoryCommerce from './icons/category-commerce.svg'
import CategoryCommunication from './icons/category-communication.svg'
import CategoryCotraitance from './icons/category-cotraitance.svg'
import CategoryEspaceverts from './icons/category-espaceverts.svg'
import CategoryGestion from './icons/category-gestion.svg'
import CategoryInformatique from './icons/category-informatique.svg'
import CategoryJuridique from './icons/category-juridique.svg'
import CategorySecurite from './icons/category-securite.svg'
import CategoryTravaux from './icons/category-travaux.svg'
import CciFullLogo from './icons/cci-full.svg'
import CciIcon from './icons/cci.svg'
import Cci2 from './icons/cci2.svg'
import Chat from './icons/chat.svg'
import Check from './icons/check.svg'
import Chevron from './icons/chevron.svg'
import Close from './icons/close.svg'
import CompanyPage from './icons/company-page.svg'
import CompanySites from './icons/company-sites.svg'
import Company from './icons/company.svg'
import Decline from './icons/decline.svg'
import Edit from './icons/edit.svg'
import ExternalIcon from './icons/external.svg'
import EyeOff from './icons/eye-off.svg'
import Eye from './icons/eye.svg'
import FacebookIcon from './icons/facebook-icon.svg'
import FeaturedIcon from './icons/featured.svg'
import Files from './icons/files.svg'
import FlagDone from './icons/flag-done.svg'
import FlagOff from './icons/flag-off.svg'
import FlagOn from './icons/flag-on.svg'
import Fold from './icons/fold.svg'
import GalNextIcon from './icons/gal-next.svg'
import GalPrevIcon from './icons/gal-prev.svg'
import Heart from './icons/heart.svg'
import HeartFull from './icons/heart-full.svg'
import Image from './icons/image.svg'
import InstagramIcon from './icons/instagram-icon.svg'
import LabelCircle from './icons/label-circle.svg'
import Label from './icons/label.svg'
import LinkedInIcon from './icons/linked-in-icon.svg'
import Location from './icons/location.svg'
import Lock from './icons/lock.svg'
import LogoWhite from './icons/logo-white.svg'
import Logo from './icons/logo.svg'
import OgcnLight from './icons/ogcn-light.svg'
import PaginationFirstIcon from './icons/pagination-first.svg'
import PaginationLastIcon from './icons/pagination-last.svg'
import Phone from './icons/phone.svg'
import Pinpoint from './icons/pinpoint.svg'
import Plus from './icons/plus-icon.svg'
import Print from './icons/print.svg'
import Profil from './icons/profil.svg'
import Quote from './icons/quote.svg'
import Refresh from './icons/refresh.svg'
import Relation from './icons/relation.svg'
import Rss from './icons/rss.svg'
import Search from './icons/search.svg'
import ShareMail from './icons/share-mail.svg'
import SmallArrowLeft from './icons/small-arrow-left.svg'
import SmallArrowRight from './icons/small-arrow-right.svg'
import Trash from './icons/trash.svg'
import TwitterIcon from './icons/twitter-icon.svg'
import Unfold from './icons/unfold.svg'
import YoutubeIcon from './icons/youtube-icon.svg'
import Download from './icons/download.svg'


export const iconsKeys = {
  Ambassador: 'Ambassador',
  Annonce: 'Annonce',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  AudioPlay: 'AudioPlay',
  AudioPause: 'AudioPause',
  AvatarSun: 'AvatarSun',
  BreadcrumbSeparator: 'BreadcrumbSeparator',
  Calendar: 'Calendar',
  CategoryCommerce: 'CategoryCommerce',
  CategoryCommunication: 'CategoryCommunication',
  CategoryCotraitance: 'CategoryCotraitance',
  CategoryEspaceverts: 'CategoryEspaceverts',
  CategoryGestion: 'CategoryGestion',
  CategoryInformatique: 'CategoryInformatique',
  CategoryJuridique: 'CategoryJuridique',
  CategorySecurite: 'CategorySecurite',
  CategoryTravaux: 'CategoryTravaux',
  Cci2: 'Cci2',
  Cci: 'Cci',
  CciFullLogo: 'CciFullLogo',
  Chat: 'Chat',
  Check: 'Check',
  Chevron: 'Chevron',
  Close: 'Close',
  Company: 'Company',
  CompanyPage: 'CompanyPage',
  CompanySites: 'CompanySites',
  Decline: 'Decline',
  Edit: 'Edit',
  External: 'External',
  Eye: 'Eye',
  EyeOff: 'EyeOff',
  FacebookIcon: 'FacebookIcon',
  Featured: 'Featured',
  Files: 'Files',
  FlagDone: 'FlagDone',
  FlagOff: 'FlagOff',
  FlagOn: 'FlagOn',
  Fold: 'Fold',
  GalNext: 'GalNext',
  GalPrev: 'GalPrev',
  Heart: 'Heart',
  HeartFull: 'HeartFull',
  Image: 'Image',
  InstagramIcon: 'InstagramIcon',
  Label: 'Label',
  LabelCircle: 'LabelCircle',
  LinkedInIcon: 'LinkedInIcon',
  Location: 'Location',
  Lock: 'Lock',
  Logo: 'Logo',
  LogoWhite: 'LogoWhite',
  OgcnLight: 'OgcnLight',
  PaginationFirst: 'PaginationFirst',
  PaginationLast: 'PaginationLast',
  Phone: 'Phone',
  Pinpoint: 'Pinpoint',
  Plus: 'Plus',
  Print: 'Print',
  Profil: 'Profil',
  Quote: 'Quote',
  Refresh: 'Refresh',
  Relation: 'Relation',
  Rss: 'Rss',
  Search: 'Search',
  ShareMail: 'ShareMail',
  SmallArrowLeft: 'SmallArrowLeft',
  SmallArrowRight: 'SmallArrowRight',
  Trash: 'Trash',
  TwitterIcon: 'TwitterIcon',
  Unfold: 'Unfold',
  YoutubeIcon: 'YoutubeIcon',
  Download: 'Download',
}


const IconWrapper = (Component, width, height) => {
  const IconWrapperStyles = {
    wrapper: {
      width: `${width}px`,
      height: `${height}px`,
      display: 'inline-flex',
    },
  }

  const useStyles = createUseStyles(IconWrapperStyles)

  const IconAsset = (props) => {
    const classes = useStyles(props)
    const { className } = props

    return (
      <Component className={cx(classes.asset, className)} />
    )
  }

  IconAsset.propTypes = {
    className: PropTypes.string,
  }
  IconAsset.defaultProps = {
    className: '',
  }

  return IconAsset
}

export const icons = {
  [iconsKeys.Ambassador]: IconWrapper(Ambassador, 24, 24),
  [iconsKeys.Annonce]: IconWrapper(Annonce, 24, 24),
  [iconsKeys.ArrowLeft]: IconWrapper(ArrowLeftIcon, 14, 10),
  [iconsKeys.ArrowRight]: IconWrapper(ArrowRightIcon, 14, 10),
  [iconsKeys.AudioPlay]: IconWrapper(AudioPlay, 31, 30),
  [iconsKeys.AudioPause]: IconWrapper(AudioPause, 31, 30),
  [iconsKeys.AvatarSun]: IconWrapper(AvatarSun, 121, 120),
  [iconsKeys.BreadcrumbSeparator]: IconWrapper(BreadcrumbSeparatorIcon, 3, 5),
  [iconsKeys.Calendar]: IconWrapper(Calendar, 24, 24),
  [iconsKeys.CategoryCommerce]: IconWrapper(CategoryCommerce, 24, 24),
  [iconsKeys.CategoryCommunication]: IconWrapper(CategoryCommunication, 24, 24),
  [iconsKeys.CategoryCotraitance]: IconWrapper(CategoryCotraitance, 24, 24),
  [iconsKeys.CategoryEspaceverts]: IconWrapper(CategoryEspaceverts, 24, 24),
  [iconsKeys.CategoryGestion]: IconWrapper(CategoryGestion, 24, 24),
  [iconsKeys.CategoryInformatique]: IconWrapper(CategoryInformatique, 24, 24),
  [iconsKeys.CategoryJuridique]: IconWrapper(CategoryJuridique, 24, 24),
  [iconsKeys.CategorySecurite]: IconWrapper(CategorySecurite, 24, 24),
  [iconsKeys.CategoryTravaux]: IconWrapper(CategoryTravaux, 24, 24),
  [iconsKeys.Cci2]: IconWrapper(Cci2, 20, 20),
  [iconsKeys.Cci]: IconWrapper(CciIcon, 20, 20),
  [iconsKeys.CciFullLogo]: IconWrapper(CciFullLogo, 100, 40),
  [iconsKeys.Chat]: IconWrapper(Chat, 21, 21),
  [iconsKeys.Check]: IconWrapper(Check, 14, 14),
  [iconsKeys.Chevron]: IconWrapper(Chevron, 10, 10),
  [iconsKeys.Close]: IconWrapper(Close, 14, 14),
  [iconsKeys.Company]: IconWrapper(Company, 24, 24),
  [iconsKeys.CompanyPage]: IconWrapper(CompanyPage, 24, 24),
  [iconsKeys.CompanySites]: IconWrapper(CompanySites, 24, 24),
  [iconsKeys.Decline]: IconWrapper(Decline, 21, 21),
  [iconsKeys.Edit]: IconWrapper(Edit, 14, 14),
  [iconsKeys.External]: IconWrapper(ExternalIcon, 8, 8),
  [iconsKeys.Eye]: IconWrapper(Eye, 24, 24),
  [iconsKeys.EyeOff]: IconWrapper(EyeOff, 24, 24),
  [iconsKeys.FacebookIcon]: IconWrapper(FacebookIcon, 22, 22),
  [iconsKeys.Featured]: IconWrapper(FeaturedIcon, 20, 20),
  [iconsKeys.Files]: IconWrapper(Files, 16, 19),
  [iconsKeys.FlagDone]: IconWrapper(FlagDone, 24, 24),
  [iconsKeys.FlagOff]: IconWrapper(FlagOff, 24, 24),
  [iconsKeys.FlagOn]: IconWrapper(FlagOn, 24, 24),
  [iconsKeys.Fold]: IconWrapper(Fold, 24, 24),
  [iconsKeys.GalNext]: IconWrapper(GalNextIcon, 40, 40),
  [iconsKeys.GalPrev]: IconWrapper(GalPrevIcon, 40, 40),
  [iconsKeys.Heart]: IconWrapper(Heart, 40, 40),
  [iconsKeys.HeartFull]: IconWrapper(HeartFull, 40, 40),
  [iconsKeys.Image]: IconWrapper(Image, 39, 36),
  [iconsKeys.InstagramIcon]: IconWrapper(InstagramIcon, 32, 32),
  [iconsKeys.Label]: IconWrapper(Label, 24, 24),
  [iconsKeys.LabelCircle]: IconWrapper(LabelCircle, 24, 24),
  [iconsKeys.LinkedInIcon]: IconWrapper(LinkedInIcon, 22, 22),
  [iconsKeys.Location]: IconWrapper(Location, 16, 18),
  [iconsKeys.Lock]: IconWrapper(Lock, 24, 24),
  [iconsKeys.Logo]: IconWrapper(Logo, 163, 44),
  [iconsKeys.LogoWhite]: IconWrapper(LogoWhite, 163, 44),
  [iconsKeys.OgcnLight]: IconWrapper(OgcnLight, 1068, 1111),
  [iconsKeys.PaginationFirst]: IconWrapper(PaginationFirstIcon, 12, 10),
  [iconsKeys.PaginationLast]: IconWrapper(PaginationLastIcon, 12, 10),
  [iconsKeys.Phone]: IconWrapper(Phone, 8, 8),
  [iconsKeys.Pinpoint]: IconWrapper(Pinpoint, 25, 24),
  [iconsKeys.Plus]: IconWrapper(Plus, 24, 24),
  [iconsKeys.Print]: IconWrapper(Print, 36, 36),
  [iconsKeys.Profil]: IconWrapper(Profil, 13, 13),
  [iconsKeys.Quote]: IconWrapper(Quote, 13, 11),
  [iconsKeys.Refresh]: IconWrapper(Refresh, 16, 16),
  [iconsKeys.Relation]: IconWrapper(Relation, 25, 24),
  [iconsKeys.Rss]: IconWrapper(Rss, 14, 14),
  [iconsKeys.Search]: IconWrapper(Search, 24, 24),
  [iconsKeys.ShareMail]: IconWrapper(ShareMail, 14, 14),
  [iconsKeys.SmallArrowLeft]: IconWrapper(SmallArrowLeft, 24, 24),
  [iconsKeys.SmallArrowRight]: IconWrapper(SmallArrowRight, 24, 24),
  [iconsKeys.Trash]: IconWrapper(Trash, 15, 19),
  [iconsKeys.TwitterIcon]: IconWrapper(TwitterIcon, 22, 18),
  [iconsKeys.Unfold]: IconWrapper(Unfold, 24, 24),
  [iconsKeys.YoutubeIcon]: IconWrapper(YoutubeIcon, 28, 20),
  [iconsKeys.Download]: IconWrapper(Download, 24, 24),
}

export default icons
